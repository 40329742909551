/* eslint-disable camelcase */
import BaseModel from '@/classes/BaseModel'
import { generateId } from '@/utils/create'

export default class CatalogCategory extends BaseModel {
  _parent_id
  _lvl
  sort
  description
  filter_settings
  children
  products_count
  products_count_category
  slug
  check

  constructor ({
    id = generateId(),
    name = '',
    parent_id,
    description = '',
    filter_settings = [],
    children = [],
    products_count = 0,
    products_count_category = 0,
    slug = '',
    lvl = 1,
    sort = 1,
    check = false
  }) {
    super({ id, name })

    this._parent_id = parent_id
    this.description = description
    this.filter_settings = filter_settings
    this.children = children
    this.products_count = products_count
    this.products_count_category = products_count_category
    this.slug = slug
    this._lvl = lvl
    this.sort = sort
    this.check = check
  }

  get parent_id () {
    return this._parent_id
  }

  get lvl () {
    return this._lvl
  }

  get countChildren () {
    return this.children.length
  }
}
