<template>
  <div class="catalog-category-tree-field column gap-s" :class="(isDisabled && !hasButtonCheck) && 'disabled'">
    <ButtonPoints v-if="!isDisabled && !hasButtonCheck" class="catalog-category-tree-field__draggable" />
    <VCheckbox
        v-if="hasButtonCheck"
        :value="modelValue.check"
        :name="String(modelValue.id)"
        class="catalog-category-tree-field__check"
        @value-changed="setDate"
        isValue
    />

    <div class='between gap-xxl'>
      <div class="column gap-s max-w-input w-100">
        <InputClear
          v-model="modelValue.name"
          :label="categoryLabel"
          class="catalog-category-tree-field__input-wrap"
          :class="modelValue.parent_id && 'catalog-category-tree-field__input-wrap--child'"
          :is-disabled="isDisabled"
          required
          :has-button-remove="hasButtonRemove"
          is-emit-remove
          @on-remove="openModal"
        />

        <VButton
          v-if="!isDisabled && hasButtonCreate"
          text="Добавить уровень"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="addSectionLvlHandler"
        />
      </div>

      <VInput
        v-if="modelValue.lvl === 1 && !isDisabled"
        v-model="modelValue.description"
        label="Примечание:"
        class="catalog-category-tree-field__note"
        type="textarea"
      />
    </div>

    <Container
      v-if="countChildren"
      class="catalog-category-tree-field__child-sections column gap-m"
      lock-axis="y"
      drop-class="catalog-category-tree-field__child-sections"
      drag-handle-selector=".catalog-category-tree-field__draggable"
      drag-class="grabbing"
      :get-child-payload="getDropPayload"
      @drop="dropCategoryHandler"
    >
      <Draggable
        v-for="(item, i) of modelValue.children"
        :key="item.id"
      >
        <CatalogCategoryTreeField
          v-model="modelValue.children[i]"
          :key="modelValue.children[i].check"
          :is-disabled="isDisabled"
          :has-button-create="hasButtonCreate"
          :has-button-remove="hasButtonRemove"
          :has-button-check="hasButtonCheck"
          @remove-category="spliceCategoryHandler"
          @check="setDate"
        />
      </Draggable>
    </Container>

    <ModalConfirmation
      v-if="isModal"
      :title="confirmationTitle"
      @confirm="destroyCategoryHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import InputClear from '@/components/Form/Vinput/InputClear'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ButtonPoints from '@/components/ui/button/ButtonPoints'
import VCheckbox from '@/components/Form/VCheckbox'

import { mixinInputModel } from '@/mixins/form/mixinInputModel'
import { mixinModal } from '@/mixins/modal/mixinModal'

import CatalogCategory from '@/classes/model/Catalog/CatalogCategory'
import { WORD_INDEX_LIST } from '@/const/wordIndexList'
import { refreshArr, spliceBy } from '@/utils/array/reactive'
import { showAlertError, showAlertWarning } from '@/utils/store/alert'
import { applyDrag } from '@/utils/array'
import { mapActions } from 'vuex'
import { isString } from '@/utils/string'

export default {
  name: 'CatalogCategoryTreeField',
  components: {
    VInput,
    ModalConfirmation,
    ButtonPoints,
    Container,
    Draggable,
    InputClear,
    VButton,
    VCheckbox
  },
  mixins: [mixinInputModel, mixinModal],
  props: {
    value: {
      type: CatalogCategory,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasButtonCreate: {
      type: Boolean,
      required: false,
      default: true
    },
    hasButtonRemove: {
      type: Boolean,
      required: false,
      default: true
    },
    hasButtonCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    isСheckСategory: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    categoryLabel () {
      return `${this.categoryLabelLvl} уровень:`
    },
    categoryLabelLvl () {
      return (WORD_INDEX_LIST[this.categoryLvl] || '10+')
    },
    categoryLvl () {
      return this.modelValue.lvl
    },
    categoryId () {
      return this.modelValue.id
    },
    countChildren () {
      return this.modelValue.countChildren
    },
    confirmationTitle () {
      return `Удалить раздел ${this.modelValue.name}?`
    }
  },
  methods: {
    ...mapActions('catalog/categories', {
      destroyCategory: 'destroyCategory'
    }),

    addSectionLvlHandler () {
      this.modelValue.children.push(new CatalogCategory({
        parent_id: this.categoryId,
        lvl: this.categoryLvl + 1,
        sort: this.countChildren + 1
      }))
    },

    async destroyCategoryHandler () {
      const canDestroy = await this.checkCanDestroyCatalogCategory()
      if (!canDestroy) return

      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        if (!isString(this.categoryId)) {
          await this.destroyCategory(this.categoryId)
        }
        this.$emit('remove-category', this.categoryId)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    spliceCategoryHandler (id) {
      spliceBy(id, this.modelValue.children)
    },

    async checkCanDestroyCatalogCategory () {
      if (this.countChildren) {
        showAlertWarning('Невозможно удалить раздел, т.к. он содержит подразделы')
        return false
      }

      if (this.modelValue.products_count_category) {
        showAlertWarning('Невозможно удалить раздел, т.к. он содержит товары')
        return false
      }

      return true
    },

    dropCategoryHandler (dropResult) {
      try {
        this.$store.commit('START_IS_LOADING_DND')

        const result = applyDrag(this.modelValue.children, dropResult)

        refreshArr(this.modelValue.children, result)
      } finally {
        this.$store.commit('FINISH_IS_LOADING_DND')
      }
    },
    getDropPayload (idx) {
      return this.modelValue.children[idx]
    },
    setDate (data) {
      this.$emit('check', data)
    }
  }
}
</script>
