<template>
  <FilterForm
    v-model="formValue"
    ref="filterForm"
    @request="request"
    @reset="reset"
  >
    <VSelect
      v-for="item of filters"
      :key="item.id"
      :label="item.name"
      :name="item.search_field"
      :options="getOptions(item.search_field)"
      multiple
      searchable
    />
    <VSelect
      label="Наличие"
      name="in_stock"
      :options="stockOptions"
    />
    <VSelect
      label="Цены"
      name="with_price"
      :options="priceOptions"
    />
    <VCheckbox
        v-model="isNew"
        label="Только новинки"
        type="switch"
    />
  </FilterForm>
</template>

<script>
import FilterForm from '@/components/Form/FilterForm'
import VSelect from '@/components/Form/VSelect'
import VCheckbox from '@/components/Form/VCheckbox'

import { mapGetters, mapState, mapMutations } from 'vuex'
import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import getValuesArray from '@/utils/array/getValuesArray'
import { checkAndSliceIds } from '@/utils/string'

export default {
  name: 'TheCatalogFilterExport',
  components: { VSelect, FilterForm, VCheckbox },
  mixins: [mixinFilterEmit],
  data: () => ({
    isNew: false,
    stockOptions: [
        {
            id: 1,
            value: true,
            name: 'Только в наличии'
        },
        {
            id: 2,
            value: false,
            name: 'Нет в наличии'
        }
    ],
    priceOptions: [
        {
            id: 1,
            value: true,
            name: 'Только с ценами'
        },
        {
            id: 2,
            value: false,
            name: 'Только без цен'
        }
    ]
  }),
  computed: {
    ...mapState('records', {
      properties: 'properties'
    }),

    ...mapGetters('records', {
      dWireAll: 'dWireAll'
    }),
    ...mapState('catalog', {
      filterList: 'filterList',
      filterExportFormValue: 'filterExportFormValue'
    }),
    ...mapGetters('catalog', {
      currentFilterSetting: 'currentFilterSetting'
    }),

    catalogHandbook () {
      return {
        productTypes: this.$store.state.handbook.productType.list,
        techBrands: this.$store.state.handbook.techBrand.list,
        techTypes: this.$store.state.handbook.techType.list,
        countries: this.$store.state.handbook.country.list
      }
    },

    filters () {
      return this.filterList
    },

    getOptions () {
      return key => {
        key = checkAndSliceIds(key)
        switch (key) {
          case 'd_wires': return this.dWireAll
          case 'country': return this.catalogHandbook.countries
          case 'tech_brand': return this.catalogHandbook.techBrands
          case 'tech_type': return this.catalogHandbook.techTypes
          case 'spring_type': return this.properties.product_type
          case 'product_type': return this.catalogHandbook.productTypes
          default: return this.properties[key]
        }
      }
    },

    formValue: {
      get () {
        return this.filterExportFormValue
      },
      set (value) {
        this.SET_FILTER_EXPORT_FORM_VALUE(value)
      }
    }
  },
  methods: {
    ...mapMutations('catalog', {
      SET_FILTER_EXPORT_FORM_VALUE: 'SET_FILTER_EXPORT_FORM_VALUE'
    }),
    request (data) {
      const keyList = ['tech_brand_ids', 'tech_type_ids', 'd_wires', 'material_ids', 'spring_type_ids', 'country_ids', 'product_type_ids']
      
      const dataFiltered = this.getFiltered(data, keyList)

      const preparedData = prepareFormDataIdArray(dataFiltered, keyList, { suffix: '' })
      if (preparedData.d_wires) preparedData.d_wires = getValuesArray(data.d_wires, 'name')

      const save = this.formValue
      save.is_new_at = this.isNew
      this.SET_FILTER_EXPORT_FORM_VALUE(save)
      
      const daysAgo = new Date()
      daysAgo.setDate(daysAgo.getDate() - 14)
      if (this.isNew) preparedData.is_new_at = daysAgo
      else preparedData.is_new_at = this.isNew

      if (data.in_stock) preparedData.in_stock = data.in_stock.value
      if (data.with_price) preparedData.with_price = data.with_price.value

      this.$emit('request', preparedData)
    },

    getFiltered (data, keys) {
      const filtered = {}

      for (const key of keys) {
        filtered[key] = data[key]
      }

      return filtered
    }
  },
  mounted () {
    if (this.filterExportFormValue.is_new_at) this.isNew = this.filterExportFormValue.is_new_at
  }
}
</script>
