<template>
  <button class="button-points btn-reset" type="button">
    <span class="points"/>
  </button>
</template>

<script>
export default {
  name: 'ButtonPoints'
}
</script>

<style lang="scss">
.points {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  color: inherit;
  background-color: currentColor;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: inherit;
  }
  &::before {
    left: -10px;
  }
  &::after {
    left: 10px;
  }
}
.button-points {
  position: relative;
  width: var(--m);
  height: var(--m);
  color: var(--color-main);
}
</style>
