import { throwResponseError } from '@/utils/error/throwResponseError'
import { instance } from '@/api'

const CATALOG_EXPORT_URL = 'catalog/export'

export const CatalogExportApi = {
  export: async (params) => {
    try {
      return await instance.post(`/${CATALOG_EXPORT_URL}`, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить отчет')
    }
  }
}
