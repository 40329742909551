<template>
  <Spinner v-if="isLoading === 'get'"/>

  <div v-else style="position: relative;">
    <div class="between ai-c mb-30">
      <VButtonPageBack />

      <div class="d-flex gap-s">
        <VButton
          text="Настроить колонки"
          icon="settings"
          size="sm"
          @click.native="openModal('column-setting')"
        />

        <VButton
          text="Настроить фильтр"
          size="sm"
          icon="filter"
          :active="isFilter"
          @click.native="toggleFilter"
        />

        <VButton
          text="Экспорт в XLS"
          icon="export"
          size="sm"
          :disabled="categoriesExport.length === 0 || columnsExport.length === 0"
          :loading="isLoadingExport"
          @click.native="exportCatalog"
        />
      </div>
    </div>

    <TheCatalogFilterExport
      v-if="isFilter"
      ref="CatalogFilter"
      class="mb-m"
      @request="loadFilter"
      @reset="resetFilter"
    />

    <VForm
      class="gap-m"
      scroll-when-invalid
    >
      <div class="box">
        <div class="flex mb-30">
          <VCheckbox
                v-model="ischeckAll"
                name="0"
                style="margin-right: 20px;"
                @value-changed="checkAll"
            />
            <h1>Весь каталог</h1>
        </div>

        <Container
          class="column gap-m"
          style="padding-left: 20px;"
          drag-handle-selector=".catalog-category-tree-field__draggable"
        >
          <Draggable
            v-for="(item, i) of categories"
            :key="item.id"
          >
            <CatalogCategoryTreeField
              v-model="categories[i]"
              :key="categories[i].check"
              :is-disabled="true"
              :has-button-check="true"
              @check="checkCategory"
            />
          </Draggable>
        </Container>
      </div>
    </VForm>

    <ModalCatalogCategoryExportColunmSettings
      v-if="isModal === 'column-setting'"
      @request="saveExportColumn"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import CatalogCategoryTreeField from '@/components/views/Catalog/CatalogCategoryTreeField/CatalogCategoryTreeField'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import VButton from '@/components/simple/button/VButton'
import VForm from '@/components/Form/VForm'
import VCheckbox from '@/components/Form/VCheckbox'
import ModalCatalogCategoryExportColunmSettings from '@/components/views/Catalog/Modal/ModalCatalogCategoryExportColunmSettings'
import TheCatalogFilterExport from '@/components/views/Catalog/TheCatalogFilterExport'
import { CatalogExportApi } from '@/api/catalog/CatalogExportApi'

import { mixinIsFilter } from '@/mixins/mixinIsFilter'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { mixinModal } from '@/mixins/modal/mixinModal'
import CatalogCategory from '@/classes/model/Catalog/CatalogCategory'
import { mapState, mapActions, mapMutations } from 'vuex'
import { goToPage } from '@/utils/router'
import { showAlertError } from '@/utils/store/alert'

export default {
  name: 'CatalogCategoriesExport',
  components: {
    CatalogCategoryTreeField,
    Container,
    Draggable,
    VButton,
    VButtonPageBack,
    VForm,
    VCheckbox,
    ModalCatalogCategoryExportColunmSettings,
    TheCatalogFilterExport
  },
  mixins: [mixinIsLoading, mixinModal, mixinIsFilter],
  data: () => ({
    categories: [],
    ischeckAll: false,
    isLoadingExport: false,
    categoriesExport: [],
    columnsExport: [
      'article',
      'category',
      'name', 
      'product_d_wire'
    ]
  }),
  computed: {
    ...mapState('catalog', {
      filterExport: 'filterExport'
    }),
    stateCategories () {
      return this.$store.state.catalog.categories.categoriesTree?.children || []
    }
  },
  async mounted () {
    try {
      this.startLoading('get')
      await this.getAllCategories()
      this.fetchAndSetFilters()
      this.syncData()
    } finally {
      this.finishLoading()
    }
  },
  methods: {
    ...mapActions('catalog/categories', {
      getAllCategories: 'getAllCategories'
    }),
    ...mapActions('catalog', {
      fetchAndSetFilters: 'fetchAndSetFilters'
    }),
    ...mapMutations('catalog', {
      SET_COLLUMN_SETTING: 'SET_COLLUMN_SETTING',
      SET_FILTER_EXPORT: 'SET_FILTER_EXPORT',
      RESET_FILTER_EXPORT: 'RESET_FILTER_EXPORT',
      RESET_FILTER_EXPORT_FORM_VALUE: 'RESET_FILTER_EXPORT_FORM_VALUE'
    }),
    goToPage,

    syncData () {
      this.categories = this.stateCategories.map((item, i) => this.mapCategory(item, i, 1))
    },

    mapCategory (item, i, lvl) {
      return new CatalogCategory({
        ...item,
        lvl,
        sort: item.sort || (i + 1),
        children: item.children.map((item, i) => this.mapCategory(item, i, lvl + 1))
      })
    },
    setCategoryExport (data, value) {
        data.check = value

        if (value) this.categoriesExport.push(data._id)
        else this.categoriesExport = this.categoriesExport.filter(val => val !== data._id)
        
        data.children.forEach(item => {
            item = this.setCategoryExport(item, value)
        })
        return data
    },
    checkCategory (val, data = this.categories) {
        data.map(item => {
            if (item._id === val.id) item = this.setCategoryExport(item, val.value)
            else if (item.children.length) this.checkCategory(val, item.children)
        })
    },
    checkAll (val) {
      const catalog = [
        {
          children: this.categories,
          _id: 0,
          check: val.value
        }
      ]
      this.checkCategory(val, catalog)
    },
    saveExportColumn (data) {
      this.SET_COLLUMN_SETTING(data)
      this.columnsExport = []
      const array = Object.entries(data)
      array.forEach(item => {
        if (item[1]) this.columnsExport.push(item[0])
      })
      this.closeModal()
    },
    loadFilter (filterData) {
      this.SET_FILTER_EXPORT(filterData)
      this.toggleFilter()
    },
    resetFilter () {
      this.RESET_FILTER_EXPORT()
      this.RESET_FILTER_EXPORT_FORM_VALUE()
    },
    async exportCatalog () {
      try {
        this.isLoadingExport = true
        const params = {
          file_type: 'xlsx',
          category_ids: this.categoriesExport,
          fields: this.columnsExport,
          filter: this.filterExport
        }
        console.log('export', this.categoriesExport, this.columnsExport, this.filterExport)
        const response = await CatalogExportApi.export(params)

        const file = response.data.export_path
        const a = document.createElement('a')
        a.href = file
        a.download = file.substr(file.lastIndexOf('/') + 1)
        a.click()
        a.remove()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.isLoadingExport = false
      }
    }
  },
  destroyed () {
    this.RESET_FILTER_EXPORT()
    this.RESET_FILTER_EXPORT_FORM_VALUE()
    this.SET_COLLUMN_SETTING({
        article: true,
        category: true,
        name: true,
        product_d_wire: true,
        files_images: false,
        product_diameter: false,
        product_length: false,
        product_n_n1: false,
        product_width: false,
        product_weight: false,
        product_material: false,
        product_winding_dir: false,
        product_tech_brand: false,
        product_prices: false,
        spring_type: false,
        in_stock: false
    })
  }
}
</script>
