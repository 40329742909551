<template>
  <ModalForm
    v-model="formData"
    title="Настройки полей"
    class="modal-title-page-edit"

    text-btn-confirm="Сохранить"
    btn-size="l"
    only-btn-submit

    @request="saveSectionSettingHandler"
    @reject="onReject"
  >
    <div class="d-grid d-grid--columns-2 gap-xs">
      <VCheckbox
        v-for="item of columns"
        :key="item.id"
        :label="item.name"
        :name="item.search_field"
      />
    </div>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import VCheckbox from '@/components/Form/VCheckbox'

export default {
  name: 'ModalCatalogCategoryExportColunmSettings',
  components: { VCheckbox, ModalForm },
  data: () => ({
    formData: {}
  }),
  created () {
    this.syncFormData()
  },
  computed: {
    columns () {
      return this.$store.state.catalog.exportColumnList
    }
  },
  methods: {
    saveSectionSettingHandler (data) {
      this.$emit('request', data)
    },

    syncFormData () {
      const data = this.$store.getters['catalog/getCollumnSetting']
      this.formData = { ...data }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
